import { StaticImage } from "gatsby-plugin-image";
import LinkedInIcon from '../../images/case-study/ugc/linkedin.svg';
import icon1 from "../../images/svg/1.svg";
import icon2 from "../../images/svg/2.svg";
import icon3 from "../../images/svg/3.svg";
import icon4 from "../../images/svg/4.svg";

const saasboomiData = {
  color: "#FFF5E4",
  heroTitle: `Find how <br />SaaSBOOMi<br />achieved real-time <br />engagement with <br />Premagic`,
  heroImage: (
    <StaticImage src="../../images/case-study/saasboomi/hero.png" alt="" />
  ),
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "1,230",
      },
      {
        label: "Photos",
        value: "5,450",
      },
      {
        label: "Photo Downloads",
        value: "1,250",
      },
      {
        label: "Social Posts",
        value: "293",
      },
    ],
    title: "Background",
    content: `SaaSBOOMi Annual is Asia's biggest SaaS conference attended by over 1100 founders and 80+ top VCs for two days of knowledge-sharing and networking. Held in Chennai, SaaSBOOMi Annual 2023 was the largest since its inception. It featured keynote speakers, workshops, investor-startup meetings, and more.`,
    footer: `The event was organized by the team at SaaSBOOMi Foundation, close-knit pay it forward community of B2B SaaS founders from India.`,
    host: "SaaSBOOMi Foundation",
    organizer: "SaaSBOOMi Foundation",
    industry: "Entrepreneurship",
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/saasboomi/2.png" alt="" />,
    },
    {
      img: <StaticImage src="../../images/case-study/saasboomi/3.png" alt="" />,
    },
  ],
  gallery: (
    <StaticImage src="../../images/case-study/saasboomi/gallery.png" alt="" />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading: "Tackling the hurdle of managing event photos",
      info1:
        "Before implementing Premagic, SaaSBOOMi encountered several challenges in managing event photos effectively. Sorting and delivering event photos was a time-consuming ordeal, taking 2-3 weeks to complete.",
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/saasboomi/avatar.png"
            alt=""
          />
        ),
        message: `“ We used to face a tedious process of sorting photos, followed by uploading them to Google Drive, and finally sending out a mass email to all attendees. Often attendees were less inclined to sift through thousands of images to find their own and resorted to uploading low-quality photos on social media platforms themselves. “`,
        name: "Malavika Velayanikal",
        info: `Director of Initiatives & <br /> Events, SaaSBOOMi`,
      },
      info2: `This often resulted in diminished post-event engagement and presented a significant hurdle for SaaSBOOMi in maintaining attendee enthusiasm and fostering a sense of community after their events. <br /><br /> Recognizing the need for a comprehensive event management platform that could seamlessly connect and engage their audiences, SaaSBOOMi sought an alternative to their previous method. Their team began their search for a future-proof solution capable of addressing this issue effectively and finally landed on Premagic as their preferred platform.`,
    },
    {
      title: "The Solution",
      subheading: "Using Premagic to deliver an unforgettable event experience",
      info1: `“How can we make the SaaSBOOMi Annual experience something unforgettable for every founder in attendance?” While this was a question that had kept the organisers up many a night, their collaboration with Premagic eased most of their concerns. <br /><br />
                From making it easier for the events team to sort out and organize the event photos to streamlining the registration process and generating reliable data, Premagic was able to meet multiple needs of the SaaSBOOMi team, who were confident they’d found a lasting solution. <br /><br />
                “Since the scale of the event was the largest we’ve had, we needed to make sure the logistics and overall founder experience was top-notch. Premagic felt like the right fit for that.”<br /><br />
                The main hassle that Premagic addressed was the efficient organization and sorting of photos. With the power of AI, this manually exhausting grunt work was completely eliminated. The guests could register pre-event along with a selfie to ensure that they receive all their face-matched photos from the event as soon as they are ready. This registration process was beneficial because it streamlined the entire photo management workflow.<br /><br />
                Employing Premagic's AI-based photo delivery at the venue solved the issue of delayed photo sharing. Attendees receive their event photos on the same day, driving immediate social engagement right from the start of the event.`,
    },
    {
      title: "The Results",
      subheading: "Surpassing 1000 attendees with top satisfaction!",
      info1: `With Premagic powering SaaSBOOMi Annual 2023, the event went smoothly and was well-received by the founders. According to data from a feedback survey sent out after the event, over 90% of attendees participated in both days of the Annual, with a high recommendation score of 9.57/10!
                <br /><br />Here's a quick recap:`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Instant Photo Delivery",
          description: `With Premagic, attendees received their event photos on the same day. This led to immediate social engagement from day 1 of the event, with beautifully captured moments shared across various platforms.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Organized, Accessible Photos",
          description: `Premagic ensured all photos were well-organized in the form of an online gallery and easily accessible for attendees. This improved post-event interactions, encouraging SaaSBOOMi to leverage Premagic for all their future events.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Exponential Increase in Social",
          description: `The impact was profound as SaaSBOOMi witnessed over 100 LinkedIn posts tagged with event photos. This level of social engagement was unprecedented for them, significantly enhancing their online presence.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Better Marketing & Insights",
          description: `Not only did the in-gallery sponsor placement help the team provide better ROI to its clients, but the analytics dashboard also helped identify opportunities for repeated success and room for improvement in future events.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Embracing a bright event future with Premagic`,
      info1: `While SaaSBOOMi Annual 2023 marked a resounding success, the SaaS world keeps on spinning. Malavika and her crew are all geared up for more thrilling events and projects in collaboration with Premagic. The aim is to simply keep creating bigger and better experiences for their attendees.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/saasboomi/avatar.png"
            alt=""
          />
        ),
        message: `“ Premagic really came through with solutions that not only saved us substantial time and effort but also felt like having an extra member on our team. We will definitely be using it for our future events. “`,
        name: "Malavika Velayanikal",
        info: `Director of Initiatives & <br /> Events, SaaSBOOMi`,
      },
    },
  ],
  UGCData:{
    hashtag:"#SaaSBoomiUGCHub",
    background:'#F1EDFE',
    data:[

        {
          image: <StaticImage src='../../images/case-study/ugc/saasboomi/1.png' alt="" className="mb-5" />,
          content: `At <span class="text-blue-700 font-semibold">SaaSBOOMi</span> We created “Pathbreakers” award category for <span class="text-blue-700 font-semibold">Zoho</span> achieving $1B SaaS revenue and <span class="text-blue-700 font-semibold">Freshworks</span> for a spectacular NASDAQ IPO both these brands put India SaaS into global map and it extra proud moment personally for me as both these brands are from Chennai, Tamilnadu.`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar.png' alt="" />,
          name: 'Suresh Sambandam',
          designation: 'Founder & CEO at Kissflow',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: 'pb-4'
        },
        {
          content: `Super pumped to announce that Sprinto won the Devtools Startup of the year award at <span class="text-blue-700 font-semibold">SaaSBOOMi</span>, Asia’s Biggest SaaS Conference 🏆
          Deeply indebted to our customers and partners, who supported and encouraged us.`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar2.png' alt="" />,
          name: 'Sprinto',
          designation: 'Making compliance faster & easier',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: 'pb-4'
        },
        {
          content: `🎉 Just got back from <span class="text-blue-700 font-semibold">SaaSBOOMi</span> Annual in Chennai and it was 🔥! So many amazing startups showcasing their innovative solutions in the Saas space. 💡I am beyond intrigued by the potential of Saas startups in India and can't wait to see what the future holds. 🚀 It was a blast.`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar3.png' alt="" />,
          name: 'Prashanth Ganesh',
          designation: 'Founder at PreSkale',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: 'pb-5'
        },
        {
          content: `I've been searching for inspiration to kickstart my third chapter at Hubilo, and (of course), it was an event that again came to my rescue. I attended the <span class="text-blue-700 font-semibold">SaaSBOOMi's</span> annual event last weekend at Chennai, where more than 1000+ SaaS founders came together to network and exchange information. I wasn't sure how the event would go nor the impact it would have, but I walked away super inspired by the kind of work that’s happening within the SaaS space in India. The quality and maturity of the sessions was at par or better with events that I have attended globally.`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar4.png' alt="" />,
          name: 'Vaibhav Jain',
          designation: 'Founder & CEO at Hubilo',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: 'pb-5'
        },
        {
          content: `<span class="text-blue-700 font-semibold">#SaasBoomi</span> annual has always been a fixture in my calendar since I started <span class="text-blue-700 font-semibold">Wingman</span> by ClariFrom learning during sessions to finding my mentors and co-warriors - this community has given me so much.`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar5.png' alt="" />,
          name: 'Shruti Kapoor',
          designation: 'Head of International Business',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: ''
        },
        {
          image: <StaticImage src='../../images/case-study/ugc/saasboomi/2.png' alt="" className="mb-5" />,
          content: `We had an amazing time attending Saasboomi Annual 2023, one of the largest events for Saas founders in India!The opportunity to connect with industry leaders and like-minded individuals was invaluable.We came away with many great takeaways and insights that we can't wait to implement.Thanks to <span class="text-blue-700 font-semibold">SaaSBOOMi</span> for organizing such a fantastic event!`,
          avatar : <StaticImage src='../../images/case-study/ugc/saasboomi/avatar5.png' alt="" />,
          name: 'Shruti Kapoor',
          designation: 'Head of International Business',
          logo: <img src={LinkedInIcon} alt="" />,
          classNames: ''
        }
      ]
}

}

export default saasboomiData
