import { StaticImage } from "gatsby-plugin-image";


const glaGlobalData =  {
  color: '#d2e3f4',
  heroTitle: `Breaking language barriers at GLA's Global Logistics Conference`,
  heroImage: <StaticImage src="../../images/case-study/gla-global/hero.png" alt="" />,
  metrics: {
    quote: `Lost in transalation? Not anymore! Check out how Premagic helped GLA bridge language barriers at their milestone global event.`,
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/gla-global/banner.png" alt="Banner" />
    },
    {
      img: <StaticImage src="../../images/case-study/currency-research/banner.png" alt="Banner" />
    },
  ],
  gallery: <StaticImage src="../../images/case-study/gla-global/gallery.png" alt="gallery" />,
  caseStudyData: [
    {
      title: 'The Challenge',
      info1: `The GLA Global Logistics Alliance has always been about connecting the world. With a network of over 7,000 logistics companies across 170+ countries, GLA’s mission is to help businesses find reliable partners, expand their networks, and create opportunities for growth.<br/><br/>When they hosted the <b>10th GLA Global Logistics Conference </b> in Dubai, the stakes were higher than ever. Over 1,500 delegates from 100+ countries gathered to connect, learn, and expand their businesses, making this event one of the most influential in global logistics.<br/><br/>From 70,000+ one-on-one meetings to 200 booths showcasing innovative solutions, the conference was meticulously planned.<br/><br/>But there was one major gap: <b>a seamless way to distribute event photos to their attendees worldwide.</b>`,
    },
    {
      title: 'A New Challenge in a New Market',
      info1: `For years, GLA had relied on a local photo distribution tool in China. It worked perfectly for their domestic events—built for the Chinese market, with a familiar interface and seamless communication in Mandarin.<br/><br/>However, when planning their <b>10th GLA Global Logistics Conference</b> in Dubai—a truly global event with attendees from diverse backgrounds—they faced a new challenge.<br/><br/>But Dubai was different.<br/><br/>This was a <b>global</b> stage. Attendees spoke different languages, used different platforms, and had different expectations. GLA needed a <b>photo-sharing solution that worked for everyone</b>—without compromising on efficiency or security.<br/><br/>And there was another challenge: <b>Enterprise privacy compliance.</b> GLA’s policy didn’t allow <b>personally identifiable information (PII) to be shared externally</b>, meaning they needed a partner who could integrate with their existing app—without collecting sensitive data.<br/><br/>After researching multiple solutions, they found Premagic.`,
      

    },
    {
      title: 'How Premagic Delivered',
      info1: `GLA had a few key requirements for a photo-sharing platform:<br/><br/><ul style="list-style-type: disc; padding-left: 20px">
      <li class='mb-2'><b>A globally accessible solution:</b> A user-friendly experience that felt intuitive to everyone, regardless of language or location. </li>
      <li class='mb-2'><b>Automated photo syncing:</b> A way to <b>sync photos directly from Google Drive</b>, eliminating the hassle of manual uploads</li>
      <li class='mb-2'><b>Enterprise-grade security:</b> A system that <b>distributes photos via GLA’s mobile app</b>, without sending any <b>PII data</b>.</li>
      <li class='mb-2'><b>Personalized photo delivery:</b> Every attendee received their own <b>personalized album</b>, making photo access effortless.</li>
      </ul>`,
      testimonial: {
        img: (
          <StaticImage src="../../images/case-study/gla-global/mevin.png" alt="" />
        ),
        message: `“GLA's challenge was unique—we weren’t just translating an experience, we were reimagining it for a global audience. Our goal was to keep things as smooth as they were used to, while making sure every attendee—whether they spoke English, Arabic, or Mandarin—had an effortless experience.“`,
        name: "Mevin Babu",
        info: `Account Manager,<br/> Premagic`,
      },
      info2:`Premagic checked all the boxes—while adding an extra layer of convenience and engagement for attendees.`,

      gridViewTitle:'Key Features That Made It Work',
      inlineLink:{
        text:'HOW NASSCOM EMPOWERED SPONSOR-AUDIENCE ENGAGEMENT WITH PREMAGIC',
        link: 'https://premagic.com/case-study/nasscom',
      },
    },
    {
      title: 'The Premagic Effect: A Seamless Photo Experience',
      info1: `With Premagic, GLA’s attendees got an effortless, <b>no-hassle photo experience</b> that felt intuitive from start to finish.<br/><br/>`,
      gridViewData: [{
        title: 'Easy Access, No Language Barriers:',
        description: `Photos were organized by day, with folders labeled in both English and Chinese, making it simple for attendees to find their pictures.`
      },
      {
        title: 'Boosting Brand Visibility:',
        description: `Every photo carried a branded watermark, doubling as a promotional tool. GLA even used the watermark space to tease their next big event—a simple touch that created organic buzz for the future.`
      },
      {
        title: 'Smart Organization:',
        description: `Instead of a chaotic mix of photos, attendees found neatly categorized albums that made reliving the event effortless.`

      },
      {
        title: 'Global Standards, Local Comfort:',
        description: `The solution worked for a global audience but kept elements familiar to GLA’s Chinese operations, ensuring a smooth transition for their team.`

      },
      ],
    },
    {
      title: 'A Success Worth Repeating',
      info1: `The results spoke for themselves.<br/><br/>GLA’s attendees loved how seamless the experience was. Their event photos spread across social media within minutes, amplifying their brand beyond the conference walls.<br/><br/>The success was so undeniable that GLA chose Premagic again for their <b>11th Global Logistics Conference in Bangkok.</b>`,
      inlineLink:{
        text:'HOW STEP 2024 TURNED ATTENDEES INTO ADVOCATES WITH PREMAGIC',
        link: 'https://premagic.com/case-study/step-conference',
      }
    },
    {
      title: 'A New Standard for GLA Events',
      info1: `From breaking language barriers to turning event photos into a branding powerhouse, Premagic didn’t just meet GLA’s needs—it raised the bar for how they approached event marketing.<br/><br/>Would you like to see <b>how Premagic can transform your events?</b> Let’s talk.`,
    },
  ],
  isCTAActive:true,
}



export default glaGlobalData;
