import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"
import { btn, btnPrimary } from "../../ds/button.module.css"
import {
  ArrowWhiteDown,
  BlackArrowRight
} from "../../ds/Shapes"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { CASE_STUDY_TYPES } from "./CaseStudy"

type Props = {
  currentPage: CASE_STUDY_TYPES | "home"
  itemsToShow: 3 | 6
}

const moreCaseStudies = [
  {
    type: "gitexglobal",
    title:
      "Learn how Premagic converted the attendees at GITEX GLOBAL into content creators",
    url: "/case-study/gitex-global/",
    image: (
      <StaticImage
        src="../../images/case-study/gitex-global/gitex_banner.png"
        alt=""
      />
    ),
  },
  {
    type: "stepconference",
    title:
      "Discover how Step 2024 turned attendees into advocates with Premagic",
    url: "/case-study/step-conference/",
    image: (
      <StaticImage
        src="../../images/case-study/stepconference/step_banner.png"
        alt=""
      />
    ),
  },
  {
    type: "wowawards",
    title: "Find out how WOW Awards uses Premagic to wow their audience",
    url: "/case-study/wowawards/",
    image: (
      <StaticImage
        src="../../images/case-study/wowawards/wow_banner.png"
        alt=""
      />
    ),
  },
  {
    type: "currencyresearch",
    title: "Breaking Stereotypes at the Banknote Conference.",
    url: "/case-study/currency-research",
    image: (
      <StaticImage
        src="../../images/case-study/currency-research/thumbnail.png"
        alt=""
      />
    ),
  },
  {
    type: "gla-global",
    title: "Bridging language barriers at GLA's milestone global event.",
    url: "/case-study/gla-global",
    image: (
      <StaticImage
        src="../../images/case-study/gla-global/thumbnail.png"
        alt=""
      />
    ),
  },
  {
    type: "g2live",
    title: "Driving exponential sign-up growth with innovative strategies.",
    url: "/case-study/g2-live",
    image: (
      <StaticImage
        src="../../images/case-study/g2-live/1.png"
        alt=""
      />
    ),
  },
  {
    type: "asiaberlin",
    title:
      "Explore how AsiaBerlin transformed the ABS2023 experience with Premagic",
    url: "/case-study/asiaberlin/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/asiaberlin.png"
        alt=""
      />
    ),
  },
  {
    type: "nasscom",
    title: "Learn how Nasscom uses Premagic to empower sponsor audience",
    url: "/case-study/nasscom/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/nasscom.png"
        alt=""
      />
    ),
    hideInHome: true,
  },
  {
    type: "redsummit",
    title:
      "Explore the Premagic upgrade that made the RED Summit unforgettable",
    url: "/case-study/real-estate-development-summit/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/redsummit.png"
        alt=""
      />
    ),
  },
  {
    type: "umagine",
    title: `Learn how Premagic helped in UmagineChennai's grand success`,
    url: "/case-study/umagine/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/umagine.png"
        alt=""
      />
    ),
    hideInHome: true,
  },
  {
    type: "saasboomi",
    title: "Find how SaaSBOOMi achieved real-time engagement with Premagic",
    url: "/case-study/saasboomi/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/saasboomi.png"
        alt=""
      />
    ),
    hideInHome: true,
  },
  {
    type: "showsofindia",
    title:
      "Learn how EVA Live rekindled live entertainment at Shows of India with Premagic.",
    url: "/case-study/showsofindia/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/showsofindia.png"
        alt=""
      />
    ),
  },
  {
    type: "aio",
    title: "Discover how Premagic Resolved DAM Challenges at AIOC Kochi",
    url: "/case-study/aioc/",
    image: (
      <StaticImage src="../../images/case-study/morecasestudy/aio.png" alt="" />
    ),
  },
  {
    type: "jsconf",
    title:
      "Learn how Premagic optimized the attendee experience at JSConf India",
    url: "/case-study/jsconf/",
    image: (
      <StaticImage
        src="../../images/case-study/morecasestudy/jsconf.png"
        alt=""
      />
    ),
  },
]

export default function MoreCaseStudy(props: Props) {
  const { currentPage = "home", itemsToShow = 6 } = props
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  let moreCaseStudiesToShow = moreCaseStudies.filter(
    item => item.type !== currentPage
  )
  moreCaseStudiesToShow =
    currentPage === "home"
      ? moreCaseStudiesToShow.filter(item => !item?.hideInHome)
      : moreCaseStudiesToShow
  const [isGridExpanded, toggleGridView] = useState(false)
  return (
    <section className={`${currentPage !== "home" ? "mt-40 mb-20" : "mb-20"}`}>
      {currentPage !== "home" && (
        <h2 className="text-black text-center text-3xl md:text-5xl font-bold my-10">
          More related success stories
        </h2>
      )}
      <div
        className="px-5 md:px-0 mx-auto md:flex md:flex-wrap"
        style={{ width: containerWidth }}
      >
        {moreCaseStudiesToShow
          .slice(0, isGridExpanded ? moreCaseStudiesToShow.length : itemsToShow)
          .map(item => (
            <div className="rounded-xl p-7 md:w-1/3 group">
              <div className="p-3 rounded-xl flex flex-col border border-white group-hover:border-slate-300 pb-5">
                <div className="rounded-xl overflow-hidden">{item.image}</div>
                <p className="p-5 font-medium text-base text-black mb-5">
                  {item.title}
                </p>
                <Link
                  to={item.url}
                  className="flex text-base mt-auto items-center ml-5"
                >
                  <span className="mr-2 text-sm font-medium text-black">
                    Read Full Article
                  </span>
                  <BlackArrowRight className="transition delay-100 duration-300 ease-in-out group-hover:translate-x-2" />
                </Link>
              </div>
            </div>
          ))}
      </div>
      <div className="text-center mt-10">
        {!isGridExpanded && moreCaseStudiesToShow.length > itemsToShow && (
          <button
            className={`${btn} ${btnPrimary} w-max`}
            onClick={() => toggleGridView(true)}
          >
            <span className="mr-2">Show more</span>
            <span className="block">
              <ArrowWhiteDown />
            </span>
          </button>
        )}
        {isGridExpanded && moreCaseStudiesToShow.length > itemsToShow && (
          <button
            className={`${btn} ${btnPrimary} w-max`}
            onClick={() => toggleGridView(false)}
          >
            <span className="mr-2">Show less</span>
            <span className="block rotate-180">
              <ArrowWhiteDown />
            </span>
          </button>
        )}
      </div>
    </section>
  )
}
