import { StaticImage } from "gatsby-plugin-image";

import LinkedInIcon from '../../images/case-study/ugc/linkedin.svg';
import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const gitexGlobalData =  {
  color: '#ffe5e5',
  heroTitle: `Learn how Premagic converted the attendees at GITEX GLOBAL into content creators`,
  heroImage: <StaticImage src="../../images/case-study/gitex-global/hero_inside.png" alt="" />,
  metrics: {
    title: 'Background',
    content: `GITEX GLOBAL stands out as a major force for innovation and opportunity. As the world’s largest tech and startup event, GITEX has become known for showcasing the latest technologies, making big business connections, and shaping the future of digital innovation. Every year, it brings together thousands of exhibitors, speakers, and attendees worldwide, creating an unmatched space for technology and business growth.</br></br>`,
    content2:`<b>From exhibitor to essential: Premagic's journey at GITEX Global</b></br></br>Premagic’s journey at GITEX Global goes beyond just another business story—it’s a true startup success tale.`,
    history:[
      {
        title:'2022',
        image:<StaticImage src="../../images/case-study/gitex-global/2022.png" className='rounded-xl' alt="Banner" />,
        content:`Our relationship with GITEX began in 2022 when we joined the event as one of many hopeful exhibitors. Though our setup was modest, we had a big vision: <b>to convert event attendees into event marketers</b></br></br>As we witnessed the event's immense scale, we recognised how Premagic's AI-powered event marketing solutions could be the perfect fit at GITEX. We demonstrated the capabilities of our AI-powered photo distribution tool, which not only provides every attendee with seamless access to their event photos but also encourages organic user-generated content.</br></br>This caught the attention of GITEX organisers, who recognised its potential as <b>a powerful tool for boosting user-generated content ( UGC ) & social media engagement.`
      },
      {
        title:'2023',
        image:<StaticImage src="../../images/case-study/gitex-global/2023.png" className='rounded-xl' alt="Banner" />,
        content:`Premagic moved from being an exhibitor to becoming <b>GITEX’s official media partner</b>. This shift showed the strength of our technology and its understanding of large events.</br></br>We made an intriguing observation about the previous editions of GITEX: <b>There wasn’t a system in place for delivering event photos to attendees, exhibitors</b>, and <b> speakers</b>. While ample photographers were deployed, access to the photos taken by the official photographers was limited. </br></br>Photos were manually curated and sent only to specific speakers at the event through a laborious process. This was a pressing issue since we encountered so many attendees who were desperate to get their own photos.</br></br>
        Premagic became the key to addressing a gap that the GITEX team couldn't cover—the private and exclusive access to photos of every attendee, especially those who preferred a more discreet approach.`
      },
      {
        title:'2024',
        image:<StaticImage src="../../images/case-study/gitex-global/2024.png" className='rounded-xl' alt="Banner" />,
        content:`After a long journey, <b>GITEX is now officially a paid customer of Premagic</b>. Signing the contract for GITEX 2024 was more than just a business milestone; it demonstrated the true value Premagic brings to global events.</br></br></br>With our fleet of branded photographers on-site, we took on the challenge of organically marketing the world’s largest tech event. Since 2022, our event marketing solutions have evolved, equipping us to better address the marketing challenges GITEX faces before, during, and after the event.</br></br>Our journey — <b>from exhibitor to official partner to eventually a vendor</b> — at the world’s largest tech event, says a lot about the rising importance of AI-powered solutions in today’s event marketing landscape.`
      },
    ],
    host: 'Dubai World Trade Centre',
    organizer: 'DWTC Exhibitions - ICT Sector',
    industry: 'Technology'
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/gitex-global/banner.png" alt="Banner" />
    },
    {
      img: <StaticImage src="../../images/case-study/gitex-global/banner2.png" alt="Banner2" />
    },
  ],
  gallery: <StaticImage src="../../images/case-study/gitex-global/gitex.png" alt="gallery" />,
  caseStudyData: [
    {
      title: 'The Challenge',
      info1: `When Premagic joined GITEX Global 2024, we set big goals to boost the event’s organic marketing efforts. Following the success of GITEX 2023, the organisers again entrusted us with managing the event’s photo distribution. </br></br>This time, however, they had a more targeted focus — <b>prioritising photo coverage for speakers and exhibitors </b> rather than the broader participant coverage we’d aimed for in 2023.</br></br>Another challenge for the GITEX team was <b>finding effective ways to boost social media engagement</b>. Despite being a globally recognised event, social sharing by attendees is crucial, as it significantly impacts the event's overall global visibility.</br></br> Finding ways to encourage all event participants to share organic content about GITEX was a key engagement strategy that the GITEX team was eager to implement`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/gitex-global/rizona.png" alt="" />,
      //   message: `<i>"With a show like <b>GITEX GLOBAL</b>, the sheer size and diversity of our audience make it challenging to drive the level of interaction we aim for. Operating on an international scale, we’re always looking for unique ways to engage audiences, boost social media activity, and maintain visitor interest year-round—especially onsite. Premagic has been instrumental in helping us achieve this seamlessly."</i>`,
      //   name: 'Rozana Noja',
      //   info: 'AVP Marketing & Partnerships'
      // },
      video: 'https://www.youtube.com/embed/pXbbc1wakTM?si=V3nEWFQCUqlbAO9C',
      info2:`<b>Increasing exhibitor and speaker coverage:</b> While photo distribution has been a part of GITEX in previous years, there has been a considerable gap in photo coverage for exhibitors and speakers compared to attendees. Exhibitors are essential to the event’s success, driving much of the activity and engagement that attendees come to experience. </br></br>Ensuring they receive the visibility they deserve enhances their participation and strengthens their connection to the event, making them more likely to return in future years. By prioritising exhibitor and speaker coverage, the GITEX team aimed to enrich their experience and position them as central figures within the event narrative.</br></br><b>Driving social media engagement:</b> Despite GITEX’s strong global brand presence, the organisers faced the challenge of further amplifying its reach. They wanted to find a way to drive more authentic, user-generated content that could deepen the event's impact online.</br></br>This challenge was about more than just extending reach; it was about creating a digital buzz that would build anticipation and FOMO, especially for those unable to attend. The goal was to keep GITEX in the digital spotlight, ensuring it stayed top of mind for future participants and partners, sparking a cycle of excitement and ongoing interest.`,
    },
    {
      title: 'The Solution',
      info1: `At GITEX Global 2024, we set out to redefine event engagement by focusing on key areas that would elevate the experience for exhibitors, speakers, and attendees.`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/gitex-global/shreya.png" alt="" />,
        message: `<i>"Photo distribution is definitely a key component in ensuring that all event attendees have a memorable experience at the show, which in turn encourages them to get more involved. Premagic made this seamless and efficient, ensuring attendees received their photos instantly and shared them widely!
        "</i>`,
         name: 'Shreya Shyamsunder',
        info: 'Marketing Executive <br/> Dubai World Trade Center'
      },
      info2: `<b>1. Boosting exhibitor and speaker visibility</b><br/><br/>
        We deployed a <b>dedicated team of on-site photographers</b> strategically positioned to capture high-impact moments with exhibitors and speakers. Key areas like booths and presentation zones received focused attention to ensure comprehensive coverage of exhibitor activities. Exhibitors could also request team photos at their booths, ensuring their entire team was featured.<br/><br/><b>Watermarked with the GITEX logo, every photo was delivered seamlessly to exhibitors’ WhatsApp and email during the event</b>, powered by our AI-powered photo distribution technology for real-time accessibility and easy social sharing. </br></br><b>2. Creating a buzz with Avatars</b></br></br>
        We introduced our new feature: <b>AI avatars.</b> This feature is designed to break away from the usual tech event atmosphere and bring an element of fun to everyone attending. With AI avatars, attendees could snap a selfie, choose from a selection of themed templates, and instantly receive their own custom avatars.</br></br>These avatars, crafted to match the event’s theme, were easy to share and became a unique way to boost engagement unexpectedly. Our goal was not only to enhance the attendee experience but also to create fresh, shareable content online, building excitement and buzz around the event.</br></br><b>3. I-am-attending posters for attendees, speakers & exhibitors </b></br></br>
        Whether you’re an attendee, speaker, or exhibitor, we know how powerful it is when someone shares their own experience online. To turn every participant into an advocate for GITEX, we provided <b>easy-to-use templates for event marketing posters</b> in the form of "I'm Attending," “I’m Exhibiting,” and "I'm Speaking" posters. These automated, pre-designed, and customisable posters made it simple for participants to share event highlights with their networks, amplifying GITEX’s reach and creating buzz through personal connections.`,
    },
    {
      title: 'The Results',
      info1: `The GITEX team initially had modest expectations, so our results were even more impactful. Our goals weren’t just met—they were exceeded in ways that completely changed how the organisers viewed event engagement technology.`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
      //   message: `<i>"Premagic simplified attendee engagement and extended the event experience beyond the physical event itself."</i>`,
      //   name: 'Dana Sakr',
      //   info: 'Marketing Manager <br/> Step Conference'
      // },
    
      gridViewData: [{
        icon: <img src={icon1} alt="" />,
        title: 'High engagement with event photos',
        description: `Our photographers captured over <b>16,000 high-definition images, with over 7,000 downloaded</b> and shared on social media. The watermarked photos helped increase GITEX’s reach, and exhibitors were enthusiastic about using these photos to share their experiences online.`
      },
      {
        icon: <img src={icon2} alt="" />,
        title: 'Rise in organic user-generated content',
        description: ` Premagic’s UGC prompt led to a noticeable increase in attendee-driven social media posts, generating numerous <b>organic social media posts & countless online stories</b>. The inclusion of GITEX’s watermark added brand visibility, making every shared image a powerful marketing asset.`

      },
      {
        icon: <img src={icon3} alt="" />,
        title: 'Pre-event buzz with “I’m Attending” posters',
        description: `Attendees, speakers, and exhibitors widely shared their “I’m Attending” posters, creating excitement and FOMO around the event. The organisers appreciated the easy, Canva-like poster design options that allowed seamless drag-and-drop customisation.`

      },
      {
        icon: <img src={icon4} alt="" />,
        title: 'Fun engagement with AI-powered avatars',
        description: `The AI-powered avatars were a major hit, with <b>over 2,200 avatars created by 900 attendees</b>. Social media was buzzing with these fun, branded avatars, adding a unique layer of engagement and enhancing GITEX’s visibility with a playful touch. `

      }
      ],
      info2: `This shift from early doubts to major success shows a big lesson: innovative technology, when used with clear goals, can really transform even the most established events. Premagic's success in deploying strategies at GITEX Global has now set a new standard for event engagement.`
    },
    {
      title: 'The Future',
      info1: `After Premagic's colossal success at GITEX GLOBAL 2024, we’re all set to elevate the experience at GITEX Africa in April 2025. The organisers were thrilled with the impact Premagic created and the dedication shown by our on-ground team.`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
      //   message: `<i>"To my fellow marketers, Premagic can really boost your organic presence and engagement during and after the event. I’d highly recommend it for any event business.”</i>`,
      //   name: 'Dana Sakr',
      //   info: 'Marketing Manager <br/> Step Conference'
      // },
      info2: `For the upcoming event, they’re planning even more extensive coverage with a larger team. They have shown interest in exploring additional features like registration with multi-zone face check-in and our sponsorship modules to enhance the attendee experience even further.</br></br>
      Looking ahead to the next iteration of their flagship event, the DWTC team is confident in their choice to integrate Premagic as a platform to boost attendee engagement and drive organic marketing.`
    }
  ],
  successMetrics:{
    title: 'Key Success Metrics',
    description:'Our photographers uploaded <b>over 16K high-definition photos. 7K+ photos</b> were downloaded and shared on social media, and generating 500+ organic posts. This engagement delivered a projected <b>ROI of $50K+</b> for GITEX',
    gridValues: [
      { id: 1, label: "Photos", value: "16,185" },
      { id: 2, label: "Views", value: "29,093" },
      { id: 3, label: "Downloads", value: "7,970" },
      { id: 4, label: "Direct Social Shares", value: "509" },
      { id: 5, label: "Face Search", value: "3,798" },
      { id: 6, label: "Registration", value: "4,104" },
      { id: 7, label: "Delivery Rate", value: "69.6%" },
      { id: 8, label: "ROI", value: "$50,738" },
    ],
  },
  UGCData: {
    hashtag: "#GitexGlobal2024UGCHub",
    background: '#ffe5e5',
    data: [
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/vikas_post.png' alt="Post" className="mb-5" />,
        content: `What an incredible experience at GITEX Global 2024🚀<br/><br/>
        GITEX brought together industry leaders and innovators, showcasing the latest trends in AI, cybersecurity, cloud computing, and digital transformation. It was an amazing opportunity to connect, learn, and explore groundbreaking technologies that are shaping the future.<br/><br/>
        The energy, innovation, and collaboration were truly inspiring!`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/vikas.png' alt="Avatar" />,
        name: 'Vikas Kumar Singh',
        designation: 'Quality Auditor/Coordinator at Department of Civil Aviation',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-4'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/hima_post.png' alt="Post" className="mb-5" />,
        content: `Exciting Times at GITEX Global 2024 in Dubai!** 🌟<br/><br/>We had an incredible 5 days filled with innovation, collaboration, and invaluable insights at <span class="text-blue-700 font-semibold">#GITEX2024</span>, alongside my partner,<span class="text-blue-700 font-semibold">NGBS</span> with <span class="text-blue-700 font-semibold">Adil ALIGOD Rachid Bouhaj Nadine Benzenati</span>, and my colleague, <span class="text-blue-700 font-semibold">Shamira Mothojakan</span> . A huge thank you to our partners for the warm welcome and for sharing your expertise and experience so generously. Your hospitality has been unparalleled.<br/><br/>We are profoundly grateful for this opportunity and are eager for many more collaborations to come. Additionally, a heartfelt thank you to the Morocco delegation for your warm welcome and enriching discussions.<br/><br/>Looking forward to future endeavors and continued growth together!`,
        avatar:  <StaticImage src='../../images/case-study/ugc/avatar_placeholder.png' alt="Avatar" />,
        name: 'Hema Tahadil',
        designation: 'Senior Consultant specializing in IT Collaboration at FRCI with Scrum expertise',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-4'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/shijin_post.png' alt="Post" className="mb-5" />,
        content: `Thrilled to be part of <span class="text-blue-700 font-semibold">GITEX Global 2024</span>!
        Excited to explore groundbreaking tech innovations and transformative solutions shaping the future.</br></br>
        Looking forward to connecting with industry leaders, innovators, and changemakers at the world’s largest tech showcase! 🚀`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/shijin.png' alt="Avatar" />,
        name: 'Shijin Prasad',
        designation: 'Head of IT Department at Cure Medical Centers',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/sreemathy_post.png' alt="Post" className="mb-5" />,
        content: `Excited to share my experience from <span class="text-blue-700 font-semibold">#GITEXGLOBAL2024</span> as an event enthusiast!<br/><br/>The future of tech-driven events is here, and GITEX GLOBAL has set a new benchmark this year with two standout event innovations that personally wowed me!<br/><br/>1️⃣ App Innovation for Networking: The seamless networking GITEX app powered by the latest technology was such a convenient move. From intelligent matchmaking, requesting meetings, to real-time updates, this app brought networking to a whole new level (whoever came up with the Tinder/Bumble style "swipe - right" connecting within the app is a genius!!!)<br/><br/>2️⃣ AI Photography at the Venue: AI took over the photography game at GITEX! With smart cameras and photographers capturing candid moments, the venue was filled with stunning, AI-enhanced images that captured the energy of the event. All we had to do was to scan a common bar code which takes us to <span class="text-blue-700 font-semibold">Premagic</span> website, take a selfie to register our face and all the pictures we were in, were AI matched, and easily downloadable and shareable.<br/><br/> `,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/sreemathy.png' alt="Avatar" />,
        name: 'Sreemathy CA',
        designation: 'Event Management @ Dubai College of Tourism (DCT) | DET - Dubai Economy and Tourism',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: ''
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/baharan_post.png' alt="Post" className="mb-5" />,
        content: `✨ GITEX 2024 was a success! ✨<br/><br/>We had an amazing time showcasing Trio's innovative solutions, connecting with industry leaders, and exploring the latest tech trends. A big thank you to everyone who visited our booth and engaged with our team. 😍 <span class="text-blue-700 font-semibold">Trio</span>`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/baharan.png' alt="Avatar" />,
        name: 'Baharan Ahmadi',
        designation: 'Business Development Executive @Trio / Trio Education & Business / Cybersecurity Solutions / MDM / B2B / EdTech / SaaS',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/tareq_post.png' alt="Post" className="mb-5" />,
        content: `Excited to have attended GITEX 2024! It was a great experience connecting with our Tahaluf clients, colleagues, and IT leaders and exploring the latest innovations.<br/><br/>Grateful for the chance to connect with so many brilliant minds!! 🤝`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/tareq.png' alt="Avatar" />,
        name: 'Tareq Marie',
        designation: 'Service & Support Manager | After-Sales Support & SLAs | Project Management | SDM |Managed Services | Strategic Outsourcing | ILM3 | Mini Master | Risk Management | Resource Allocation | ITIL',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/anjalika_post.png' alt="Post" className="mb-5" />,
        content: `Here’s a look back at our presence at GITEX 2024. What a dynamic and impactful week it has been!<br/><br/>This year, GITEX provided an incredible opportunity for us to showcase <span class="text-blue-700 font-semibold">Riverbed Technology</span>'s next-gen AI-Powered Observability platform and engage with forward-thinking organizations across the region. From insightful conversations to live demos, we showed how Riverbed empowers organizations with insights that matter!<br/><br/>A big thank you to everyone who took the time to connect with us and share your challenges, goals, and vision. Your engagement has been invaluable, and we’re excited to continue supporting you on your digital journey.<br/><br/>If you weren’t able to meet us at GITEX, you can still discover how Riverbed can help elevate your digital performance: <span class="text-blue-700 font-semibold">https://lnkd.in/dMCkvWF5</span> , or schedule a strategic discussion with our team: <span class="text-blue-700 font-semibold">https://t.ly/RBW7L</span>.<br/><br/>We’re excited about what’s ahead and are ready to continue driving digital success together!`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/anjalika.png' alt="Avatar" />,
        name: 'Anjelika Shimko',
        designation: 'Regional Marketing Manager EMEA Emerging at Riverbed',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/gitex-global/bittu_post.png' alt="Post" className="mb-5" />,
        content: `<span class="text-blue-700 font-semibold">GITEX GLOBAL Largest Tech & Startup Show in the World </span><br/><br/>✨ Stay Ahead 🤝 Network`,
        avatar: <StaticImage src='../../images/case-study/ugc/gitex-global/bittu.png' alt="Avatar" />,
        name: 'Bittu George',
        designation: 'Director Sales Gupshup Inc | CXaaS ⚙️ | WhatsApp APIs ⚙️',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: ''
      },

    ]
  }
}



export default gitexGlobalData;