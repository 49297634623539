
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ThumbnailImage from '../../../images/thumbnails/gla_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Breaking language barriers at GLA's Global Logistics Conference"
    description="The GLA Global Logistics Alliance has always been about connecting the world. With a network of over 7,000 logistics companies across 170+ countries, GLA’s mission is to help businesses find reliable partners, expand their networks, and create opportunities for growth."
    metaImage={ThumbnailImage}
    >
    <Header />
    <CaseStudy type={CASE_STUDY_TYPES.GLA_GLOBAL} />
    </Layout>
  );
}

export default Index;