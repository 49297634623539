import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const asiaBerlinData = {
  color: "#FEF0F2",
  heroTitle: `Explore how AsiaBerlin transformed the ABS2023 experience with Premagic`,
  heroImage: (
    <StaticImage src="../../images/case-study/asiaberlin/hero_inside.png" alt="" />
  ),
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "1,150",
      },
      {
        label: "Photos",
        value: "1,40,500",
      },
      {
        label: "Photo Downloads",
        value: "16,500",
      },
      {
        label: "Social Posts",
        value: "130",
      },
    ],
    title: "Background",
    content: `AsiaBerlin Summit 2023 is an annual event fostering economic ties between Berlin and Asia, facilitating connections between Berlin startups and Asian markets. With two decades of experience, it serves as a vital platform for networking among investors, startups, and industry players, breaking down ecosystem barriers and promoting valuable connections.`,
    footer: `Asia Berlin Forum e.V. (ABF) is the official organizer promoting collaboration between Berlin's political, business, science, and cultural sectors and the Asia-Pacific region.`,
    host: "Asia Berlin",
    organizer: "Asia Berlin Forum e.V. (ABF)",
    industry: "Business and Technology",
  },
  images: [
    {
      img: (
        <StaticImage src="../../images/case-study/asiaberlin/2.png" alt="" />
      ),
    },
    {
      img: (
        <StaticImage src="../../images/case-study/asiaberlin/3.png" alt="" />
      ),
    },
  ],
  gallery: (
    <StaticImage src="../../images/case-study/asiaberlin/gallery.png" alt="" />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading:
        "Finding a streamlined and innovative event marketing solution for ABS2023",
      info1: `Highlighting the importance of collaboration, AsiaBerlin organized a real-time engagement event, uniting technology innovators, startups, and entrepreneurs worldwide.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/asiaberlin/avatar.png"
            alt=""
          />
        ),
        message: `“ Our aim was to engage the audience, foster a positive brand association for sponsors, and find a comprehensive event marketing solution for ABS2023.“`,
        name: "Swantje Gies",
        info: `Managing Director, <br />AsiaBerlin Forum`,
      },
      info2: `In their quest for a better event experience in 2023, AsiaBerlin explored various event management solutions, including Premagic. The team conducted extensive research, recognizing the significance of user-generated content (UGC) in event marketing and exposure. They actively sought a platform that would cater to this need, enhancing their event marketing strategies. Additionally, they were determined to introduce an element of novelty, aiming to astonish attendees with an unprecedented and unforgettable experience.`,
    },
    {
      title: "The Solution",
      subheading: "Making a difference with Premagic’s AI Solutions",
      info1: `After carefully exploring various customer platforms, the team ultimately decided that Premagic was the best choice. It not only met their requirements but also offered additional features that caught their attention.<br /><br />
                "Premagic's AI photo distribution platform presented a fantastic tool for encouraging online content generation, which could significantly expand the event's reach and this aligned perfectly with our event strategy," mentioned Swantje.<br /><br />
                By selecting a partner that synced with their requirements and offered ongoing support, the team could concentrate on delivering a high-value event.`,
    },
    {
      title: "The Results",
      subheading: "A successful, data-rich event experience",
      info1: `Thanks to Premagic, the AsiaBerlin team successfully organized an event that rivalled its previous year's success. This two-day gathering boasted a remarkable 50% live attendance rate and featured a stellar lineup of 74 speakers, all while drawing in over 1100 registrants.<br /><br />
                Here's a quick recap of what went down during those 2 action-packed days:`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Effective Event Marketing",
          description: `By delivering event photos instantly, Premagic empowered AsiaBerlin attendees to engage promptly, fostering a strong online presence. This real-time marketing strategy attracted more attention and participation.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Enabling Content Repurposing",
          description: `Premagic's efficient photo delivery streamlined the process for AsiaBerlin, enabling the attendees to repurpose event content quickly. This resulted in a wealth of shareable, user-generated content, extending the event's impact long after it concluded.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Delighted Sponsors",
          description: `With precise sponsor branding options and enhanced attendee engagement, Premagic provided AsiaBerlin's sponsors with measurable outcomes and increased visibility. This delighted sponsors and strengthened their partnership for future events.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Instant Wow Experience",
          description: `With the real-time photo delivery, the attendees were treated to a novel experience, leaving them impressed and fostering a positive brand association.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Building a greater event impact with Premagic`,
      info1: `Having witnessed the effectiveness of Premagic, Swantje and her team are not only confident but also enthusiastic about collaborating on more successful events in the future.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/asiaberlin/avatar.png"
            alt=""
          />
        ),
        message: `“ Premagic really supported us during the AsiaBerlin summit. We saw great value in the partnership, and the responsiveness of the Premagic team was a big part of that. We know we can count on Premagic for future events. “`,
        name: "Swantje Gies",
        info: `Managing Director, <br />AsiaBerlin Forum`,
      },
    },
  ],
}

export default asiaBerlinData
