import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const showsOfIndiaData = {
  color: "#F6F6F6",
  heroTitle: `Discover how EVA Live rekindled live entertainment at Shows of India with Premagic.`,
  heroImage: (
    <StaticImage src="../../images/case-study/showsofindia/hero_inside.png" alt="" />
  ),
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "20,100",
      },
      {
        label: "Photos",
        value: "56,600",
      },
      {
        label: "Photo Downloads",
        value: "6,650",
      },
      {
        label: "Social Posts",
        value: "1,230",
      },
    ],
    title: "Background",
    content: `The Shows Of India Conclave, an initiative by Eva Live and Eventfaqs Media in association with EEMA, held its third edition in Mumbai this year.`,
    footer: `Rebranded from its erstwhile identity (India IP Conclave), this first-of-its-kind initiative brought together IP promoters, influencers, and industry leaders in the branded entertainment, culture marketing, and live entertainment industry from across the globe under one roof.`,
    host: "EventFaqs Media + EEMA",
    organizer: "Eva Live",
    industry: "Live entertainment & marketing",
  },
  images: [
    {
      img: (
        <StaticImage src="../../images/case-study/showsofindia/2.png" alt="" />
      ),
    },
    {
      img: (
        <StaticImage src="../../images/case-study/showsofindia/3.png" alt="" />
      ),
    },
  ],
  gallery: (
    <StaticImage
      src="../../images/case-study/showsofindia/gallery.png"
      alt=""
    />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading:
        "Seeking new strategies to elevate event engagement and participation.",
      info1: `India's Live Entertainment industry is poised for exponential growth in the coming years. The team at EVA Live needed to deliver a physical experience that could renew and celebrate the power of live entertainment. <br /><br />At the same time, as a company that values dedicated brand supporters and is laser-focused on enhancing the customer experience, EVA Live is always on the lookout for new ways to attract and engage more attendees at their events, and exploring innovative platforms to showcase their sponsor brands. Not to forget instant content that can create powerful and positive associations.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/showsofindia/avatar.png"
            alt=""
          />
        ),
        message: `“ Our aim is to sustain SOI by providing unforgettable experiences and thoughtfully curated, top-notch content for our attendees. “`,
        name: "Deepak Choudhary",
        info: `Founder & MD-EVA Live, <br />EVENTFAQS Media`,
      },
    },
    {
      title: "The Solution",
      subheading: "Creating personalized experiences with cutting-edge tech",
      info1: `As the EVA Live team worked on the preparations for SOI Conclave 2023, they remained focused on finding tools to support their goal of enhancing engagement, tailoring content, and ensuring sponsor visibility at the event.<br /><br />
                "Personalization was a central theme at SOI," Deepak explained. "We wanted to amp up our event experiences like never before. Like tailored content suggestions and instant assistance, all aimed at making our attendees feel special and understood.”<br /><br />
                While they were assembling their event tech portfolio, the team ensured that all the tech was easy to use and helpful for the attendees to engage with their peers.<br /><br />
                "We were particularly excited about the instant photo delivery service," Deepak noted. "It allowed attendees and exhibitors to share event photos in real-time so effortlessly." <br /><br />
                With a history of collaboration on previous events, EVA Live and Premagic had a longstanding partnership, which made working together even easier.`,
    },
    {
      title: "The Results",
      subheading:
        "Bringing creativity and technology together to craft an impactful event experience",
      info1: `Premagic's AI-based photo distribution played a crucial role in enhancing the in-person experience at SOI 2022, ensuring it lived up to its promise of providing a top-notch event for attendees. Here's how Premagic contributed to achieving this goal:`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Instant User-Generated Content",
          description: `With Premagic's rapid photo distribution, we generated a wealth of user-generated content in real-time. This engaging content not only boosted the event's credibility but also proved essential for increasing brand awareness and effective event promotion.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Lasting Impressions",
          description: `Thanks to Premagic's unique features, the conclave truly amazed and entertained attendees, creating an unforgettable experience that seamlessly aligned with our vision.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Delighted Sponsors",
          description: `By allowing us to add personalized Calls to Action (CTAs) within the photo albums, Premagic empowered our audience to actively engage with sponsor brands, resulting in higher sponsor-audience interaction.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Enhanced Attendee Experience",
          description: `Premagic's instant delivery of over 2,000 event photos significantly elevated attendee satisfaction and participation, making the event truly memorable.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Strengthening their future event strategy with Premagic`,
      info1: `Thanks to the tremendous success of the entire experience, the EVA Live team is excited about collaborating with Premagic again.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/showsofindia/avatar.png"
            alt=""
          />
        ),
        message: `“ Our team was genuinely delighted to partner with such a helpful and solution-oriented company. It was an amazing experience! “`,
        name: "Deepak Choudhary",
        info: `Founder & MD-EVA Live, <br />EVENTFAQS Media`,
      },
      info2: `Reviewing all the data generated from the platform, Deepak gained valuable insights into attendee engagement patterns, which will inform their strategy for future events. "It's a challenge to figure out how to cater to all attendees' preferences. With the data showing that people really enjoyed a particular part of the event, it's a no-brainer to incorporate more of that in the future."`,
    },
  ],
}

export default showsOfIndiaData
