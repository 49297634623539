import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useEffect, useState } from "react"
import { btn, btnPrimary } from "../ds/button.module.css"
import {
  ArrowBlackDown,
  ArrowWhiteDown,
  WhiteArrowRight
} from "../ds/Shapes"
import PremagicWhiteLogo from "../images/logo/premagic-logo-white.svg"
import HeroShapeRight from "../images/svgs/hero_shapes2.svg"
import useWindowDimensions from "../utils/useWindowDimensions"
import { Dropdown } from "./Dropdown"
import { close_icon, hamburger, header_sticky } from "./Header.module.css"

const caseStudies = [
  {
    to: "/case-study/gitex-global",
    title: "GITEX Global 2024",
    description: "Transforming attendees into content creators.",
  },
  {
    to: "/case-study/step-conference",
    title: "Step Conference",
    description: "Creating a stellar global event that surpassed expectations.",
  },
  {
    to: "/case-study/g2-live",
    title: "G2 Live",
    description: "Driving exponential sign-up growth with innovative strategies.",
  },
  {
    to: "/case-study/umagine",
    title: "UmagineChennai",
    description: "Delivering an experience that goes beyond just networking.",
  },
  {
    to: "/case-study/saasboomi",
    title: "SaaSBOOMi Annual",
    description: "Tackling the hurdle of event photos management",
  },
  {
    to: "/case-study/nasscom",
    title: "Nasscom GCC Conclave",
    description: "Empowering sponsor-audience engagement ",
  },
  {
    to: "/case-study/wowawards",
    title: "Wow Awards",
    description: "Leveraging the power of instant delivery to create an impact",
  },
  {
    to: "/case-study/showsofindia",
    title: "Shows of India",
    description: "Merging creativity and technology to craft an impactful event experience",
  },
  {
    to: "/case-study/asiaberlin",
    title: "Asia Berlin",
    description: "A streamlined and innovative event marketing solution for ABS2023",
  },
  {
    to: "/case-study/currency-research",
    title: "Currency Research",
    description: "Breaking Stereotypes at the Banknote Conference.",
  },
  {
    to: "/case-study/real-estate-development-summit",
    title: "Real Estate Development Summit",
    description: "Simplifying interactions for enhanced UGC by real estate professionals.",
  },
  {
    to: "/case-study/gla-global",
    title: "GLA Global",
    description: "Bridging language barriers at GLA's milestone global event.",
  },
  {
    to: "/case-study/jsconf",
    title: "JSConf India",
    description: "Creating connections through digital tools for the tech community.",
  },
  {
    to: "/case-study/yuva-galam",
    title: "Yuva Galam Padayatra",
    description: "Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success.",
  },
  {
    to: "/case-study/gtech",
    title: "GTECH Kerala Marathon",
    description: "Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution.",
  },
];

const solutionsMenu = [
  {
    to: "/smart-photo-distribution-for-seamless-events/",
    title: "AI-Powered Photo Distribution",
    description: "Deliver photos effortlessly with smart face recognition.",
  },
  {
    to: "/create-event-marketing-posters/",
    title: "Event Marketing Posters",
    description: "Create personalized, shareable posters in seconds.",
  },
  {
    to: "/avatar-studio/",
    title: "Avatar Studio",
    description: "Create playful, shareable AI avatars that turn mundane event networking into a fun, memorable brand experience.",
  },
  {
    to: "/simplify-event-registration/",
    title: "Registration with Face Check-In",
    description: "Streamline registrations with cutting-edge technology.",
  },
  {
    to: "/event-networking-app/",
    title: "Ripple: Smart Event Networking",
    description: "Empower attendees to connect seamlessly.",
  },
  {
    to: "/sponsored-content/",
    title: "Sponsor Content",
    description: "Boost sponsor ROI with innovative tools.",
  },
]
function MobileNav(props: { hide , isSticky}) {

  return (
    <div className={`bg-white py-5 px-5 fixed inset-0 z-40`}>
      <div className="flex flex-col mt-10 justify-items-stretch	z-20 relative h-3/4 overflow-auto pt-[81px] h-[83dvh]">
        <h3 className="text-lg text-black">Solutions</h3>
        <Link
          className="mt-5 text-black"
          to="/sponsored-content/"
        >
         Maximize sponsor ROI
        </Link>
        <Link
          className="mt-5 text-black"
          to="/simplify-event-registration/"
        >
          Simplify Event Registration
        </Link>
        <Link
          className="mt-5 text-black"
          to="/create-event-marketing-posters/"
        >
          Event marketing posters
        </Link>
        <Link
          className="mt-5 text-black"
          to="/avatar-studio/"
        >
          Avatar Studio
        </Link>
        <Link
          className="mt-5 text-black"
          to="/smart-photo-distribution-for-seamless-events/"
        >
          Smart photo distribution
        </Link>
        <Link className="mt-5 text-black mb-10" to="/event-networking-app/">
          Event networking app
        </Link>
        {/* <Link className='mt-5 text-black mb-5' to="/gleanin-vs-premagic/">Gleanin vs Premagic</Link> */}
        <h3 className="text-lg text-black">Customers</h3>
        <div className="my-5">Buit for</div>
        <Link className="text-black" to="/customers/corporates/">
          Corporates
        </Link>
        <Link
          className="font-semibold text-lg mt-10 text-black"
          to="https://blog.premagic.com/tag/resources/"
        >
          Resources
        </Link>
        <Link
          className="font-semibold text-lg mt-10 text-black"
          to="/case-study/"
        >
          Case study
        </Link>
        {caseStudies.map((caseStudy, index) => (
          <Link key={index} className="mt-5 text-black" to={caseStudy.to}>{caseStudy.title}</Link>
        ))}
      </div>
      <Link
        to="https://app.premagic.com/"
        className={`py-2 px-12 min-[400px]:px-20 rounded-lg text-sm w-max group absolute left-2 bottom-2 bg-white `}
        style={{ border: "1px solid #1F0F61", color: "#1F0F61" }}
      >
        <span className="text-black">Login</span>
      </Link>
      <div className="absolute right-0 top-1/2 -translate-y-1/2">
        <img src={HeroShapeRight} alt="" />
      </div>
    </div>
  )
}


function StickyHeader ({isSticky, handleHamburgerClick, isExpanded}) {
  return (
    <div className={`${isSticky ? header_sticky:""} fixed top-0 right-0 w-full bg-white flex items-center justify-between px-5 py-3 !z-50 -translate-y-[80px] transition-all`}>
      <Link to="/">
        <StaticImage
          src="../images/logo/premagic-logo@2xl.svg"
          width={120}
          style={{ width: 120 }}
          alt="Premagic"
          placeholder="blurred"

        />
      </Link>
      <div className="flex items-center">
        <Link
          to="/request-a-demo/"
          className={`${btn} ${btnPrimary} text-xs relative !min-w-fit group !py-4 !px-6 mr-4`}
        >
          <span className="text-xs">Get a Demo</span>
        </Link>
        <button
          onClick={() => handleHamburgerClick()}
          className={`${hamburger} ${isExpanded && close_icon} px-3 py-2 text-white hover:text-white relative z-50`}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  )
}
type Props = {
  isWhiteLogo?: boolean
  isHome?: boolean
}

function Header(props: Props) {
  const { isWhiteLogo = false, isHome=false } = props
  const [isExpanded, toggleExpansion] = useState(false)
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768;
  const [isSticky, setIsSticky] = useState(false);

  const handleHamburgerClick = () => {
    toggleExpansion(!isExpanded)
    document.body.classList.toggle("overflow-y-hidden")
  }
  const handleScroll = () => {
    if (window.scrollY > 120 ) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.classList.remove("overflow-y-hidden")

    };
  }, []);
  // ${!isMobileUp && isSticky || isExpanded ? stickyHeader : ""}

  return (
    <div className={`${isHome && !isMobileUp ? "absolute w-full" : "relative"}`}>
      {isExpanded && <MobileNav hide={() => toggleExpansion(false)} isSticky={isSticky}/>}
        {!isMobileUp && <StickyHeader isExpanded={isExpanded} isSticky={isSticky} handleHamburgerClick={handleHamburgerClick}/>}
      <nav className={`container mx-auto lg:justify-between items-center flex w-full py-5 px-5 relative !z-40 md:pt-11`}>
        <Link to="/">
          {isWhiteLogo && !isSticky && !isExpanded ? (
            <img
              src={PremagicWhiteLogo}
              width={150}
              alt="Premagic"
              placeholder="blurred"
            />
          ) : (
            <StaticImage
              src="../images/logo/premagic-logo@2xl.svg"
              width={150}
              style={{ width: isMobileUp ? 150 : 120 }}
              alt="Premagic"
              placeholder="blurred"

            />
          )}
        </Link>

        <div className="flex justify-center">
        <div className="hidden lg:flex items-center text-sm">
            <div
              className={`inline-block hover:bg-[#a38cfa] p-2 rounded-lg ${
                isWhiteLogo ? "text-white" : "text-black"
              } mx-5 py-3 flex items-center group cursor-pointer font-medium`}
            >
             Solutions
              <div className="inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180">
                {isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />}
              </div>
              <Dropdown data={solutionsMenu}  />
            </div>
          </div>
          <div className="hidden lg:flex items-center text-sm">
            <div
              className={`inline-block hover:bg-[#a38cfa] p-2 rounded-lg ${
                isWhiteLogo ? "text-white" : "text-black"
              } mx-5 py-3 flex items-center group cursor-pointer relative font-medium`}
            >
              Customers
              <div className="inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180">
                {isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />}
              </div>
              <div
                className={`shadow-2xl border border-slate-100 rounded-xl invisible group-hover:visible transition opacity-0	group-hover:opacity-100 group-hover:translate-y-0 translate-y-2 absolute top-10 left-1/2 -translate-x-1/2 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`}
                style={{ width: "389px" }}
              >
                <div className="flex">
                  <div className="p-8">
                    <h3 className="text-lg font-semibold text-blue-800 mb-5">
                      Built for
                    </h3>
                    <Link
                      className="text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2"
                      to="/customers/corporates/"
                    >
                      Corporates
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex items-center text-sm">
            <div
              className={`inline-block hover:bg-[#a38cfa] p-2 rounded-lg ${
                isWhiteLogo ? "text-white" : "text-black"
              } mx-5 py-3 flex items-center group cursor-pointer font-medium`}
            >
              Case study
              <div className="inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180">
                {isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />}
              </div>
              <Dropdown data={caseStudies} isCaseStudy />
            </div>
          </div>
          <div className="hidden lg:flex items-center text-sm">
            <a
              href="https://blog.premagic.com/tag/resources/"
              target="_blank"
              className={`inline-block hover:bg-[#a38cfa] p-2 rounded-lg ${
                isWhiteLogo ? "text-white" : "text-black"
              } mx-5 py-3 flex items-center group cursor-pointer font-medium`}
            >
              Resources
            </a>
          </div>
        </div>

        <div className="justify-self-end hidden lg:flex items-center text-sm">
          <Link
            to="https://app.premagic.com/"
            className={`${btn} text-xs relative w-max group`}
          >
            <span className={isWhiteLogo ? "text-white" : "text-black"}>
              Login
            </span>
          </Link>
          <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs relative w-max group`}
          >
            <span className="mr-4 ml-2">Get a Demo</span>
            <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
          </Link>
        </div>
        <div className="ml-auto flex items-center lg:hidden">
          {isSticky ? <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs relative !min-w-fit group !py-4 !px-6 mr-4`}
          >
            <span className="text-xs">Get a Demo</span>
          </Link>:""}
          <button
            onClick={() => handleHamburgerClick()}
            className={`${hamburger} ${isExpanded && close_icon} px-3 py-2 text-white hover:text-white relative z-50`}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </nav>
    </div>
  )
}

export default Header
