
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ThumbnailImage from '../../../images/thumbnails/cr_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Breaking Stereotypes at the Banknote Conference"
    description="Worried that your senior audience can’t handle tech at your events? Here is how Premagic won over the older generation at Currency Research’s Banknote Conference."
    metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.CURRENCYRESEARCH} />
    </Layout>
  );
}

export default Index;