import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { animationBounceLeft } from "../../ds/animation.module.css"
import { btn, btnPrimary, } from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"
import { Camera, GreenCapsule, OrangeCapsule } from "../../ds/SvgIcons"
import G2Icon from "../../images/svgs/g2icon.svg"
import StarRating from "../../images/svgs/stars.svg"
import useWindowDimensions from "../../utils/useWindowDimensions"

export function Hero() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  const heroBgHeight = isMobileUp ? "min(90vh, 900px)" : "680px"
  const isBrowser = () => typeof window !== "undefined"

  const isUAEDomain = isBrowser() && window.location.host.includes("ae")
  return (
    <div>
      <div
        className="absolute left-0 right-0 top-0 z-10 rounded-b-3xl"
        style={{
          height: heroBgHeight,
          backgroundColor: "#7251F4",
        }}
      ></div>
      <div className="container mx-auto px-6 mt-2 relative z-20 pt-10 md:pt-0 pt-[130px] md:pt-0">
        <div className="relative rounded-lg pb-4">
          <div className="relative text-center p-22 pb-8 z-40">
            <h1 className="hidden md:block font-bold text-5xl text-white mt-10 leading-snug">
            Transform Your Attendees <br/> Into <span style={{ color: "#8EEDFF" }}>Passionate Event Marketers</span>
            </h1>
            <h1 className="md:hidden text-left font-bold text-3xl text-white mt-10 leading-snug">
            Transform <br/> Your Attendees Into<span style={{ color: "#8EEDFF" }}> Passionate  <br/> Event Marketers</span>
            </h1>
            <p className="my-6 md:my-8 text-white md:text-center text-left">
            An <span className="font-bold">AI Platform </span> That Turns Events into <span className="font-bold">Revenue Machines</span>.
            </p>
            <div className="flex justify-start md:justify-center">
            <Link
              to="/request-a-demo/"
              className={`${btn} ${btnPrimary} text-xs relative w-max group !py-4 !px-4`}
            >
              <span className="mr-4 ml-2">Get a Demo</span>
              <WhiteArrowRight
                className={` ${animationBounceLeft} transition duration-300 ease-in-out group-hover:translate-x-2 mr-2`}
              />
            </Link>
            </div>
          </div>
          <div className="flex justify-start md:justify-center items-center mb-10 mt-2 md:mt-0">
            <img src={G2Icon} className="mt-1" />
            <img className="ml-3" src={StarRating} />
            <p className="text-white text-sm">4.8 ratings</p>
          </div>
          <div className="flex justify-center mt-10 md:mt-0">
            <StaticImage
              className="mx-auto"
              style={{ width: "1080px" }}
              placeholder="blurred"
              src="../../images/events-hero.png"
              alt="Premagic Banner"
            />
          </div>
          <div className="absolute  -top-20 md:top-36 md:top-28 left-5 md:left-28 z-2">
            <StaticImage
              className="w-20 md:w-32 -ml-12 mb-16 animate-scale3 scale-75"
              src="../../images/cloud_01.png"
              alt="Cloud"
            />
            <div className="hidden md:block">
            <GreenCapsule />
            </div>
          </div>
          <div className="absolute top-40 md:top-32 -right-10 md:right-20">
            <div className="absolute -top-40 md:static">
            <OrangeCapsule />
            </div>
            <StaticImage
              className="w-20 md:w-32 mt-16 -ml-8 animate-scale1"
              src="../../images/cloud_02.png"
              alt="Cloud"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
