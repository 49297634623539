import { getAllQueryVariables, getUserOSName } from "../utils/BrowserUtils"
import { post } from "../utils/FetchUtils"
import { getUserLocation } from "../utils/UserLocationUtils"
import { trackFBEvents, trackGAEvents } from "./EventTracker"

const isProd = process.env.NODE_ENV === "production"

const API_ROOT = isProd ? "https://api.premagic.com" : "http://localhost:5000"
const API_URLS = {
  LEAD: `${API_ROOT}/register/lead/`,
  ACCOUNT: `${API_ROOT}/register/signup/`,
}

const ERRORS_INFO = {
  ALREADY_REGISTERED:
    "You have already registered with us! Login to app.premagic.com",
  TRY_AGAIN:
    "Something went wrong... Try again or write to support@premagic.com",
  PASSWORD_LENGTH: "Password is too short, it should be more that 6 characters",
  PHONE_INVALID:
    "Phone number is invalid, Enter a valid phone number eg. +919605547547",
}

const errorRegex = [
  {
    regex: /length for parameter Password/i,
    error: ERRORS_INFO.PASSWORD_LENGTH,
  },
  {
    regex: /Invalid phone number format/i,
    error: ERRORS_INFO.PHONE_INVALID,
  },
]

function getErrorKey(data) {
  if (data.error === "User already exists") {
    return ERRORS_INFO.ALREADY_REGISTERED
  }

  if (data.error === "Unknown error") {
    for (const item in errorRegex) {
      if (data.error_message.match(errorRegex[item].regex))
        return errorRegex[item].error
    }
  }

  return ERRORS_INFO.TRY_AGAIN
}

export function createLead(data: {
  name: string
  email: string
  phone: string
  avg_events_per_day: string
  lead_profile: "EVENT_PLANNER" | "PHOTOGRAPHER",
}): Promise<{
  lead_key: string
}> {
  const qsObject = getAllQueryVariables()
  const campaign = getAllQueryVariables()

  delete campaign.email
  delete campaign.phone
  delete campaign.source

  return post(API_URLS.LEAD, {
    ...data,
    platform: getUserOSName(),
    source: qsObject["source"] || document.referrer,
    campaign: JSON.stringify(campaign),
    location: JSON.stringify(getUserLocation()),
    lead_profile: data.lead_profile,
  })
    .then(response => {
      if (response.success) {
        trackGAEvents("create_lead_events", {
          eventCategory: "acquire",
          eventAction: "button_click",
          eventLabel: "request-a-demo",
        })
        return { lead_key: response.lead_key }
      }
      return Promise.reject({ error: ERRORS_INFO.TRY_AGAIN })
    })
    .catch(response =>
      Promise.reject({ error: response.error || ERRORS_INFO.TRY_AGAIN })
    )
}

export function createAccount(
  leadKey: string,
  data: {
    password: string
    pain_point: string
    team_size: string
    work_package: string
  }
): Promise<{
  download: any
}> {
  return post(API_URLS.ACCOUNT, {
    lead_key: leadKey,
    ...data,
  })
    .then(response => {
      if (response.success) {
        trackFBEvents("Purchase", { currency: "INR", value: 1.0 })

        trackGAEvents("signup", {
          eventCategory: "acquire",
          eventAction: "button_click",
          eventLabel: "signup_button_createaccount",
        })
        return response
      }
      return Promise.reject({ error: getErrorKey(response) })
    })
    .catch(response => {
      return Promise.reject({ error: getErrorKey(response) })
    })
}
